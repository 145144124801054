import Page from '@components/Layout/Page';
import Link from '@components/Link';
import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import { FaPlus } from 'react-icons/fa';

const Prize = ({ title, description, award, url }) => (
	<div className="p-3 list-none border border-white">
		<h2 className="text-2xl font-bold">{title}</h2>
		<p>{description}</p>
		<div>
			<b>Award:</b>
			<p>{award}</p>
		</div>
		{!!url && (
			<Link className="font-bold text-primary-500" outside to={url}>
				<FaPlus className="inline-block" /> More Info
			</Link>
		)}
	</div>
);

const PrizesPage: React.FC = () => {
	const data = useStaticQuery(graphql`
		{
			allLivePrizesYaml {
				nodes {
					id
					title
					type
					description
					award
				}
			}
		}
	`);
	const prizes = data.allLivePrizesYaml.nodes;
	return (
		<Page title="Prizes" live>
			<h1 className="text-5xl font-bold">Prizes</h1>

			<h2 className="my-4 font-bold text-2xl">General Prizes</h2>

			<div className="flex flex-col">
				{prizes
					.filter((prize) => prize.type === 'hackathon')
					.map((prize) => (
						<Prize key={prize.id} {...prize} />
					))}
			</div>

			{prizes.filter((prize) => prize.type === 'sponsor').length > 0 && (
				<h2 className="my-4 font-bold text-2xl">Sponsored Prizes</h2>
			)}

			<div className="flex flex-col">
				{prizes
					.filter((prize) => prize.type === 'sponsor')
					.map((prize) => (
						<Prize key={prize.id} {...prize} />
					))}
			</div>
		</Page>
	);
};

export default PrizesPage;
